@import './mixin.scss';

.query-result-container{
  position: relative;
}

.input-query {
  // width: 50%;

  @include for-phone-only {    
    width: 100%;
  }
}

.query-result {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  // width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.result-visible {
  display: flex;
  flex-direction: column;
}

/* Links inside the dropdown */
.result-visible div {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
}

.dialog-mid-larger {
  min-width: 750px;

  @include for-tablet-down {    
    min-width: auto;
}
}

/* Add a grey background color to dropdown links on hover */
.result-visible div:hover {
  background-color: #ddd;
}

.table-contain {

  td {
    color: $grey-600;
    font-weight: 500;
  }

  @include for-tablet-down {    
      max-width: 100vw;
      overflow: scroll;
  }
}
