@import './variables.scss';
@import './mixin.scss';


.settings-profile-tab{
  cursor: pointer;
  border: 1px solid $grey_200;
  padding: 10px 16px !important;
  height: 100%;
  font-size: 14px;
  @include flexItemsCenter;
  gap: 8px;

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }

  &:hover{
    color: #008561;
    // border-bottom: 2px solid #008561;
  }
}

.active-profile{
  color: #008561;
  border-color: $color_green;
  font-weight: 500;
  border-bottom-width: 1px !important;
  background-color: #D6F9E4;
}

.single-plan{
  border-radius: 6px;
  background-color: #FFF;
  height: 100%;
  padding: 15px;
  gap: 60px;
  border: 1px solid #D6EAF8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.active-plan-button{
  background-color: #008561;
  border-radius: 6px;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;

  &:hover{
    background-color: #45B39D;
  }
}

.current-plan-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: default;
}

.change-password-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 7px 10px;
  text-align: center;
  cursor: pointer;
}

.plan-pricing {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.pricing-discount {
  font-size: 20px;
  opacity: .5;
  text-decoration: line-through;
}

.toggle-contain {
  border: 1px solid #D7DBDD;
  border-radius: 50px;
  height: 63px;
  position: relative;
  cursor: pointer;
  background-color: $grey_100;

  .toggle-text {
    background: transparent;
    font-weight: 500;
    color: $grey_500;
    font-size: 16px;
  }

  .toggle-text.active {
    color: #000;
    z-index: 5;
  }

  .toggle-bar-contain {
    transition: all 500ms ease-in-out;
  }

  .toggle-bar {
    border-radius: 50px;
    background-color: #FFF;
    z-index: 3;
  }
}

.settingsTab {
  // margin-left: 77px;
  display: flex;
  align-items: center;
}

.settings_section {
  background-color: $grey_75;
  border: 1px solid $grey_200;
  border-radius: 24px;
  padding: 32px 20px;
  margin-top: 24px;
}

.settings_btn {
  border: 1px solid $color_green;
  border-radius: 8px;
  font-size: 14px;
  @include flexCenter;
  gap: 8px;
  padding: 8px 16px;
  color: $color_green;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    background-color: $grey_300;
    border: 1px solid $grey_400;
    color: #FFF;
  }
}

.notifications-card {
  border: 1px solid $grey_200;
  padding: 24px 16px;
  border-radius: 16px;

  .top-banner {
    @include flexItemsCenter;
    padding: 8px 12px;
    background-color: #FFF;
    gap: 8px;
    border-radius: 40px;
    width: fit-content;
    border: 1px solid $grey_200;
    margin-bottom: 16px;

    p {
      font-size: 12px;
      color: $grey-600;
    }
  }

  .options-card {
    background-color: #FFF;
    border-radius: 16px;
  }
}

.account-details-border {
  border-right: 1px solid $grey_200;
  @include for-tablet-down {    
    border-right: none;
    border-bottom: 1px solid $grey_200;
  }
}

.price-settings-left {
  min-width: 309px;
  margin-right: 59px;

  @include for-phone-only {
    margin-right: 0;
    min-width: 100%;
  }
}

.price-settings-right {
  background-color: #FFF;
  border-radius: 16px;
  padding: 24px 16px;
  width: 100%;
}

.mt-40 {
  margin-top: 40px;
}

.p-40-24 {
  padding-top: 40px;
  padding-bottom: 24px;
}

.add-carrier {
  border: 1px solid $color_green;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;

  svg {
    color: $color_green;
    font-size: 14px;
  }
}