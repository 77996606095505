@import './mixin.scss';
@import './variables.scss';

.user-menu-container{
  position: relative;
}

.user-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  top: 50px;
  right: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.section-card {
  background-color: $grey_75;
  border: 1px solid $grey_200;
}

.text-dark {
  color: $grey_900;
}

.icon-btn {
  width: 40px;
  height: 40px;
  @include flexCenter;
  border: 1.5px solid $color_green;
  color: $color_green;
  background: transparent;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    background-color: #D6F9E4;
  }
}

.user-menu-visible {
  display: block;
}

/* Links inside the dropdown */
.user-menu span {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.user-menu span:hover {
  background-color: #ddd;
}

.cust-profile-tab{
  padding: 16px 40px 16px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;

  &:hover{
    color: $color_green;
    border-bottom: 2px solid $color_green;
  }
}

.active-cust-profile{
  color: $color_green;
  border-bottom: 2px solid $color_green
}

.shipments-table-container{
  overflow-y: hidden;
  // max-height: 500px;
  min-height: 500px;
  margin-top: 16px;

  &:hover{
    overflow-y: scroll;
  }

  .shipments-table{
    width: 100%;

    th{
      position: sticky;
      top: 0;
      background: #F8F9F9;
      font-size: 14px;
      font-weight: 500;
      color: $grey_400;
      border-bottom: none !important;
    }

    tbody {
      background-color: #FFF;
    }

    th, td {
      border-bottom: 1px solid $grey_200;
      border-collapse: collapse;
    }

    th, td {
      padding: 15px 10px 15px 10px;
    }

    td {
      font-size: 12px;
      font-weight: 500;
      color: $grey_600;
      padding-top: 24px;
      padding-bottom: 24px;

      .status{
        padding: 8px 14px;
        border-radius: 20px;
        font-size: 10px;
        border-width: 1px;
        border-style: solid;
      }

      .Rejected, .Cancelled{
          color: #924040;
          background-color: #fde1e1;
          border-color: #924040;
      }

      .Pending {
        color: #AD6F07;
        background-color: #FEF6E7;
        border-color: #F7D394;
      }

      .PendingApproval{
          color: #464855;
          background-color: #e3e4e8;
          border-color: #464855;
      }

      .PendingPickup{
          color: #37458b;
          background-color: #dee3fa;
          border-color: #ced5f8;
      }

      .Delivered{
          color: #1f7556;
          background-color: #d6f3e9;
          border-color: #1f7556;
      }

      .PickedUp,  .GoingToPickup, .GoingToDeliver{
          color: #916c2e;
          background-color: #fcf0db;
          border-color: #916c2e;
      }
    }
  }

  tr {
    cursor: pointer;

    &:hover {
      background-color: aliceblue;
    }

    &:first-child {
      td {
        &:first-child {
          border-radius: 20px 0 0 0;
        }

        &:last-child {
          border-radius: 0 20px 0 0;
        }
      }
    }
  }
}

.show-customer-tab{
  display: block;
}

.hide-customer-tab{
  display: none;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.documents-contain {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 4rem;

  @include for-tablet-down {    
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .document-card {
    width: 100%;
    border: 1px solid #BFC9CA;
    border-radius: 10px;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    
    &:hover {
      transform: scale(1.02);
    }

    svg {
      color: #BFC9CA;
      font-size: 2.4rem;
      font-weight: 600;
    }
  }


}

.account-type-text {
  color: $color_green;
  font-weight: 500;
}

.wallet-account-info {
  width: 100%;
  margin-top: 10px;
  padding-left: 12px;

  .account-section {
    padding-bottom: 10px;
    border-bottom: 1px solid $grey_100;
    margin-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .account-label {
      font-weight: 500;
      font-size: 14px;
      color: $grey_400;
      line-height: 0;
      margin-bottom: 8px;
    }

    .account-info {
      font-size: 14px;
      font-weight: 500;
      color: $grey_600;
      margin-bottom: 0;
    }
  }
  
}

.customer-info-card {
  border: 1px solid $grey_200;
  border-radius: 20px;
  background-color: $grey_75;
  width: 155px;
  padding: 20px 0;
  @include flexCenter;
  flex-direction: column;
  gap: 24px;

  .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .info-card-icon {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid #B6E5D0;
      background-color: #E8F7F0;
      box-shadow: 0px 2.5px 5px -2.5px #B6E5D0, 0px 7.5px 10px -2.5px #68CDA040;
      @include flexCenter;

    }
    span {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $grey_500;
    }
  }

  .bottom-section {
    font-weight: 700;
    color: $grey_800;
    font-size: 20px;
    text-align: center;
  }
}

.tab-header-container {
  padding: 8px 12px;
  border: 1px solid $grey_200;
  box-shadow: 0px 1.5px 4px -1px #10192812;
  @include flexCenter;
  gap: 8px;
  color: $grey_600;
  font-weight: 500;
  font-size: 12px;
  border-radius: 40px;
}

.customer_tab_btn {
  border: none;
  border-radius: 8px;
  font-size: 14px;
  @include flexCenter;
  gap: 8px;
  padding: 8px 16px;
  color: #FFF;
  font-weight: 700;
  background: linear-gradient(180deg, #44BC86 0%, #15AB68 66.67%, #139C5F 96.87%);
  cursor: pointer;

  &:disabled {
    background-color: $grey_300;
    border: 1px solid $grey_400;
    color: #FFF;
  }
}

.customer_tab_btn_outline {
  border: 1px solid $color_green;
  border-radius: 8px;
  font-size: 14px;
  @include flexCenter;
  gap: 8px;
  padding: 8px 16px;
  color: $color_green;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    background-color: $grey_300;
    border: 1px solid $grey_400;
    color: #FFF;
  }
}

.overview-container {
  overflow: hidden;
  background-color: #FFF;
  border-radius: 14px;
  margin-top: 16px;

  .overview-list {
    padding: 12px 20px;

    .overview-label {
      font-weight: 500;
      font-size: 14px;
      color: $grey_400;
    }
    .overview-value {
      font-weight: 500;
      font-size: 14px;
      color: $grey_800;
    }
  }
}
