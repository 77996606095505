@import './mixin.scss';
@import './variables.scss';

.navbar {
    // height: 60px;
    position: fixed;
    background-color: transparent;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    z-index: 20;
    padding: 16px 10px 0 270px;

        

    .navbar-wrapper {
        background-color: $color_white;
        @include thinBorder;
        // height: 70px;
        @include flexItemsCenter;
        border-radius: 12px;
        width: 100%;
        padding: 12px 20px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .vert-divider {
            border-left: 1px solid $grey_200;
            border-right: 1px solid $grey_200;
        }

        .section-header {
            @include sectionHeader;
        }

        .section-sub {
            @include sectionSub;
        }

        .search-container {
            padding: 0 67.25px;


            input {
                height: 100%;
                border: 1px solid $grey_200;
                border-radius: 50px;
                padding: 10px 12px 10px 48px;
                background-color: $color_white;
                font-size: 14px;
                min-width: 300px;
            }

            .search-icon {
                @include flexCenter;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                background-color: #15AB68;
                position: absolute;
                top: 8px;
                left: 6px;
              }
        }

        .employee_detail {
            p {
                font-size: 14px;
                color: #000;
                font-weight: 500;
            }

            .employee-type-pill {
                border-radius: 24px;
                border: 2px solid $green_light;
                padding: 4px 12px;
                display: flex;
                gap: 4px;
                align-items: center;
                font-size: 12px;
                color: $grey_500;

                p {
                    margin: 0;
                }

                svg {
                    color: $color_green;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }

        .email_detail {
            font-size: 14px;
        }

        @include for-tablet-down {    
            margin-right: 0;
            margin-left: 16px;
            padding-left: 0;
            gap: 1rem;
        }

        .icon-container{
            position: relative;
            cursor: pointer;
            margin-top: 5px;
            // margin-right: 20px;
            border: 2px solid $grey_200;
            border-radius: 100%;
            width: 44px;
            height: 44px;
            @include flexCenter;

            @include for-phone-only {    
                margin-right: 0;
            }

            .icon{
                font-size: 16px;
                color: #000;
            }

            .counter{
                width: 15px;
                height: 15px;
                border-radius: 50%;
                background-color: #A93226;
                color: white;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -3px;
                right: -3px;
            }
        }


        .avatar{
            border-radius: 50%;
            height: 44px;
            width: 44px;
            cursor:pointer;
            overflow: hidden;
        }

        .profile-menu {
            display: none;
            position: fixed;
            background-color: #f9f9f9;
            min-width: 160px;
            border-radius: 27px;
            top: 100px;
            right: 20px;
            box-shadow: 0px 10px 18px -2px #10192812;
            z-index: 80;
        }

        .profile-menu-visible {
            display: block;
        }

        /* Links inside the dropdown */
        .profile-menu span {
            float: none;
            cursor: pointer;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            text-align: left;
        }

        /* Add a grey background color to dropdown links on hover */
        .profile-menu span:hover {
            background-color: #ddd;
        }

        .avatar:focus + .profile-menu{
            display: block;
        }
    }

}

.employeetype-icon {
    width: 16px;
    height: 16px;
    @include flexCenter;
    bottom: 0;
    right: 0;
    background-color: #FFF;
    border-radius: 100%;
}

.navbar-divider {
    height: 22px;
    width: 2px;
    border-radius: 10px;
    background-color: $grey_100;
}

.mode-toggle-container {
    height: 100%;
    padding: 3px;
    background-color: $grey_100;
    border-radius: 30px;
    @include flexCenter;
    gap: 3px;
    transition: all ease-in-out 500ms;

    .toggle-button {
        font-size: 12px;
        padding: 12px 10px;
        border-radius: 50px;
        cursor: pointer;
        font-weight: 500;
    }

    .mode-active {
        background-color: #FFF;
        color: #000;
    }

    .mode-inactive {
        color: $grey_500;
    }
}

.dialog-notification-wrap {
    width: 548px;
    padding: 20px 16px;

    .notification-header {
        font-size: 18px;
        color: $grey_800;
        font-weight: 700;
    }
    
    .notification-close {
        font-size: 26px;
        color: $grey_400;
        font-weight: 700;
    }
    
    .notification-dialog {
        &-empty {
            text-align: center;
            padding: 30px 0;
        }
    
        &-count {
            font-weight: 500;
            margin-bottom: 16px;
        }
    
        &-link {
            color: $color_green;
            font-weight: 500;
            text-align: center;
        }
    
        &-menu {
            list-style: none;
            padding: 0;
        }
    
        &-shipment-icon-class {
            background-color: #E8F7F0;
            border: 1.25px solid #B6E5D0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            @include flexCenter;
            box-shadow: 0px 2.5px 5px -2.5px #B6E5D0, 0px 7.5px 10px -2.5px #68CDA040;
        }
    
        &-user-icon-class {
            background-color: #E3EFFC;
            border: 1.25px solid #B6D8FF;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            @include flexCenter;
            box-shadow: 0px 2.5px 5px -2.5px #B6BAE5, 0px 7.5px 10px -2.5px #6D68CD40;
        }
    
        &-list-item {
            padding: 17px 20px;
            background-color: $grey_100;
            border-radius: 20px;
            display: flex;
            gap: 12px;
        }
    
        &-text-small {
            font-size: 12px;
            font-weight: 500;
        }
    
        &-message {
            color: $grey_900;
            font-weight: 500;
        }
    }
}